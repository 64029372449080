import React, { useState, useEffect } from 'react';
import './WebhookTrigger.css'; 

const WebhookTrigger = ({ currentUser, onChange, initialConfig }) => {
  console.log(initialConfig);
  const [webhookName, setWebhookName] = useState(initialConfig?.webhookName || '');
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [organizationToken, setOrganizationToken] = useState(currentUser?.organizationToken || '');
  const [jsonSchema, setJsonSchema] = useState(initialConfig?.jsonSchema || '');

  useEffect(() => {
    if (webhookName) {
      const baseUrl = 'https://gambot.azurewebsites.net/api/external/webhooks/'+currentUser.organization;
      const url = `${baseUrl}/${organizationToken}${webhookName}`;
      setGeneratedUrl(url);
      onChange({ webhookName, url, organizationToken, jsonSchema });
    }
  }, [webhookName,  jsonSchema, onChange]);

  const handleWebhookNameChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/\s+/g, ''); // Remove spaces
    setWebhookName(sanitizedValue);
  };

  const handleJsonSchemaChange = (e) => {
    setJsonSchema(e.target.value);
  };

  return (
    <div className="webhook-trigger">
      <div className="form-group inline-form-group">
        <label>Webhook Name:</label>
        <input
          type="text"
          value={webhookName}
          onChange={handleWebhookNameChange}
          placeholder="Enter webhook name"
        />
      </div>
      <div className="form-group inline-form-group">
        <label>Generated Webhook URL:</label>
        <input
          type="text"
          value={generatedUrl}
          readOnly
        />
      </div>
      <div className="form-group">
        <label>Request Body JSON Schema:</label>
        <textarea
          value={jsonSchema}
          onChange={handleJsonSchemaChange}
          placeholder="Paste your JSON schema here"
          rows="6"
          className="json-schema-textarea"
        />
      </div>
    </div>
  );
};

export default WebhookTrigger;
