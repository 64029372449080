import React, { useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import './ChatMessage.css';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel'; // To show the 'x' for failed messages

const ChatMessage = ({
    message,
    createdOn,
    currentUser,
    orgazanizationNumber,
    from,
    userName,
    isStarred,
    onStarToggle,
    item,
}) => {
    const [isMediaExpanded, setIsMediaExpanded] = useState(false);
    const formatToUserTimezone = (utcDate, timezone) => {
        if (!utcDate) return 'No date available';

        try {
            return new Intl.DateTimeFormat('en-GB', {
                timeZone: timezone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            }).format(new Date(utcDate));
        } catch (error) {
            console.error('Error formatting date:', error, 'Timezone:', timezone);
            return 'Invalid date';
        }
    };

    const handleMediaClick = () => {
        setIsMediaExpanded(true);
    };

    const closeMediaModal = () => {
        setIsMediaExpanded(false);
    };

    const renderMediaContent = (mediaType, mediaUrl) => {
        if (!mediaType || !mediaUrl) {
            return null;
        }

        switch (true) {
            case mediaType.startsWith('image'):
                return <img src={mediaUrl} alt="Media" className="chat-message-media" onClick={handleMediaClick} />;
            
            case mediaType.startsWith('video'):
                return <video controls className="chat-message-media" onClick={handleMediaClick}>
                        <source src={mediaUrl} type={mediaType} />
                        Your browser does not support the video tag.
                    </video>;
            
            case mediaType.startsWith('application/pdf'):
                return <a href={mediaUrl} download>Download Document</a>;

            default:
                return null;
        }
    };
    const renderMessageStatus = (status) => {
        const iconStyle = { fontSize: '16px' }; // Adjust font size here
    
        switch (status) {
            case 'delivered':
                return (
                    <span>
                        <CheckIcon style={{ color: 'blue', ...iconStyle }} />
                        <CheckIcon style={{ color: 'blue', ...iconStyle }} />
                    </span>
                ); // 2 blue checks
            case 'sent':
                return (
                    <span>
                        <CheckIcon style={{ color: 'gray', ...iconStyle }} />
                        <CheckIcon style={{ color: 'gray', ...iconStyle }} />
                    </span>
                ); // 2 grey checks
            case 'failed':
                return <span><CancelIcon style={{ color: 'red', ...iconStyle }} /></span>; // red X
            default:
                return null;
        }
    };
    
    return (
        <div
            className="chat-message"
            style={{
                alignSelf: from === orgazanizationNumber ? 'flex-end' : 'flex-start',
                backgroundColor: from === orgazanizationNumber ? '#dcf8c6' : '#fff',
            }}
        >
            <div className="chat-message-text">
                {/* Render media if the message type is media */}
                {item?.messageType === 'media' && item?.mediaUrl && renderMediaContent(item?.type, item?.mediaUrl)}
                <p>
                {message
                .replace(/\\n/g, '\n') // Replace literal `\n` with actual newline character
                .split('\n') // Split by newline
                .map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
                </p>

            </div>
            
            <div className="chat-message-date">
                <p>{createdOn ? formatToUserTimezone(createdOn, currentUser?.timeZone) : 'No Time Available'}</p>
            </div>
                <div className='chat-message-bottom'>
                
                <div className="chat-message-actions">
                    {isStarred ? (
                        <StarIcon onClick={() => onStarToggle(item?.messageId, false)} style={{ color: 'gold' , fontSize: '16px'}} />
                    ) : (
                        <StarBorderIcon style={{fontSize: '16px'}} onClick={() => onStarToggle(item?.messageId, true)} />
                    )}
                </div>
                <div className='chat-message-status'>
                {renderMessageStatus(item?.status)}
                </div>
                </div>
            {from === orgazanizationNumber && (
                <div className="chat-message-sender">
                    <p>{userName}</p>
                </div>
            )}

            {/* Modal for expanded media */}
            {isMediaExpanded && (
                <div className="media-modal" onClick={closeMediaModal}>
                    <div onClick={(e) => e.stopPropagation()}>
                        {/* Render the media in full size */}
                        {renderMediaContent(item?.type, item?.mediaUrl)}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatMessage;
