import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import LookUp from '../General/Lookup';
import './TemplatesHandler.css';

function isArrayByPropertyName(property) {
    return property === 'field1' || property === 'field2' || property === 'retrieveFields';
}

const TemplatesHandler = ({ organization, onTemplatesVariableChange, templateId, templateVariableQuery, recipientSource, locationName, locationAddress, longitude, latitude, setLocationName, setLocationAddress, setLongitude, setLatitude, parent }) => {
    console.log(organization);
    const [template, setTemplate] = useState({});
    const [templateObj, setTemplateObj] = useState(null);
    const [allTemplates, setAllTemplates] = useState([]);
    const [variableArr, setVariablesArr] = useState({});
    const [queries, setQueries] = useState(templateVariableQuery || []);
    const [contactsColumns, setContactsColumns] = useState(null);

    useEffect(() => {
        if ((!templateVariableQuery || templateVariableQuery.length === 0) && Array.isArray(variableArr?.body_text)) {
            const newQueries = variableArr?.body_text[0].map((variable, index) => ({
                index: index + 1,
                Variable: variable,
                dataSource1: '',
                table1: '',
                field1: [],
                conditionOperator: '',
                dataSource2: '',
                table2: '',
                field2: [],
                retrieveFields: []
            }));
            setQueries(newQueries);
            console.log("New queries set based on body_text:", newQueries);
        }
    }, [variableArr?.body_text, parent]);

    const handleSelectChange = useCallback((index, property, value) => {
        setQueries(prevQueries => {
            const updatedQueries = [...prevQueries];
            updatedQueries[index][property] = isArrayByPropertyName(property) ? [value] : value;
            return updatedQueries;
        });
        console.log(`Query at index ${index} updated:`, { property, value });
    }, []);

    useEffect(() => {
        onTemplatesVariableChange(templateObj, variableArr, queries);
        console.log("Template variables changed:", { templateObj, variableArr, queries });
    }, [variableArr, queries, templateObj]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllTemplates', {
                    organization: organization
                });
                
                // Filter approved templates
                const approvedTemplates = response.data.filter(template => template.status === "APPROVED");
    
                // Sort templates by 'modifiedOn' in descending order (most recent first)
                const sortedTemplates = approvedTemplates.sort((a, b) => {
                    return new Date(b.modifiedOn) - new Date(a.modifiedOn); // Sorting by modifiedOn, most recent first
                });
    
                setAllTemplates(sortedTemplates); // Set sorted templates to state
                console.log("All templates fetched and sorted:", sortedTemplates);
    
            } catch (error) {
                console.error('Error fetching templates:', error);
            }
        };
        fetchData();
    }, [organization]);
    useEffect(() => {
        const fetchContactsColumns = async (organization) => {
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetContactsColumnsByOrg', {
                    organization: organization
                });
                setContactsColumns(response.data);
                console.log("Contacts columns fetched:", response.data);
            } catch (error) {
                console.error('Error fetching contacts columns:', error);
            }
        };
        fetchContactsColumns(organization);
    }, [organization]);

    useEffect(() => {
        const fetchTemplateById = async (templateId) => {
            try {
                const response = await axios.post(
                    'https://gambot.azurewebsites.net/api/Webhooks/GetTemplateById',
                    { organizationId: organization, templateId: templateId },
                    { headers: { 'Content-Type': 'application/json' } }
                );
                const fetchedTemplate = response.data;
                console.log('fetchedTemplate', fetchedTemplate);
                setTemplate({ 'label': fetchedTemplate.name });
                setTemplateObj(fetchedTemplate);
                if (fetchedTemplate.components) {
                    fetchedTemplate.components.forEach(component => {
                        if (component.type === "BODY") {
                            setVariablesArr(component.example);
                        }
                    });
                }
                console.log("Template fetched by ID:", fetchedTemplate);
            } catch (error) {
                console.error('Error fetching template by ID:', error);
            }
        };

        if (templateId) {
            fetchTemplateById(templateId);
        }
    }, [templateId, organization]);

    const handleTemplateChange = (selectedTemplate) => {
        if (selectedTemplate.length > 0) {
            const selectedTemplateLabel = selectedTemplate[0].label;
            const template = allTemplates.find(template => template.name === selectedTemplateLabel);
            setTemplateObj(template);
            if (template && template.components) {
                template.components.forEach(component => {
                    if (component.type === "BODY") {
                        setVariablesArr(component.example);
                    }
                });
            }
            console.log("Template changed:", template);
        }
        setTemplate(selectedTemplate);
    };

    return (
        <div className='templatesHandler-container'>
            <div className='TemplatesHandler-container-select-template'>
                <LookUp
                    options={allTemplates.map(template => ({ value: template?.id, label: template.name }))}
                    onChange={handleTemplateChange}
                    placeholder='Select Template'
                    value={template.label}
                    defaultValue={Object.keys(template).length === 0 ? null : template}
                    allowAddNew={false}
                />
            </div>
            {templateObj && templateObj.components.some(component => component.type === 'HEADER' && component.format === 'LOCATION') && (
                <div className='templatesHandler-location-container'>
                    <h3>Location Details</h3>
                    <div className='templatesHandler-location-fields'>
                        <div className='templatesHandler-location-field'>
                            <label>Location Name:</label>
                            <input
                                type="text"
                                value={locationName}
                                onChange={(e) => setLocationName(e.target.value)}
                                required
                            />
                        </div>
                        <div className='templatesHandler-location-field'>
                            <label>Location Address:</label>
                            <input
                                type="text"
                                value={locationAddress}
                                onChange={(e) => setLocationAddress(e.target.value)}
                                required
                            />
                        </div>
                        <div className='templatesHandler-location-field'>
                            <label>Longitude:</label>
                            <input
                                type="text"
                                value={longitude}
                                onChange={(e) => setLongitude(e.target.value)}
                                required
                            />
                        </div>
                        <div className='templatesHandler-location-field'>
                            <label>Latitude:</label>
                            <input
                                type="text"
                                value={latitude}
                                onChange={(e) => setLatitude(e.target.value)}
                                required
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className='TemplatesHandler-container-tbl'>
                {templateObj && (
                    <table className='templatesHandler-tbl'>
                        <thead>
                            <tr className='templatesHandler-tbl-header'>
                                <th>Index</th>
                                <th>Variable</th>
                                <th>Data Source</th>
                                <th>Paramaters</th>
                            </tr>
                        </thead>
                        <tbody>
                            {queries.map((query, index) => (
                                <tr key={index}>
                                    <td>{query.index}</td>
                                    <td>{query.Variable}</td>
                                    <td>
                                        <select onChange={(e) => handleSelectChange(index, 'dataSource1', e.target.value)} value={query.dataSource1}>
                                            <option value="">Select Data Source</option>
                                            <option value="data_source1_recipientSource">Recipient Source</option>
                                            <option value="data_source1_HardCoded">Hard Coded</option>
                                            <option value="data_source1_integratedDB">integrated-DB</option>
                                            {parent === "OutboundMessageAction" ?
                                                <option value="data_source1_webhook">Webhook</option>
                                                : ''
                                            }
                                        </select>
                                    </td>
                                    {query.dataSource1 === 'data_source1_recipientSource' && (
                                        <td className='templatesHandler-tbl-parmeter-select'>
                                            <select onChange={(e) => handleSelectChange(index, 'parameters_ContactsColumns_Select', e.target.value)} value={query.parameters_ContactsColumns_Select}>
                                                <option value="">Select Column</option>
                                                {contactsColumns && contactsColumns.map((column, colIndex) => (
                                                    <option key={colIndex} value={column}>{column}</option>
                                                ))}
                                            </select>
                                        </td>
                                    )}
                                    {query.dataSource1 === 'data_source1_HardCoded' && (
                                        <td className='templatesHandler-tbl-parmeter-text'>
                                            <input
                                                type='text'
                                                placeholder='Please Enter Text'
                                                onChange={(e) => handleSelectChange(index, 'parameters_hardCoded_Text', e.target.value)}
                                                value={query.parameters_hardCoded_Text}
                                            />
                                        </td>
                                    )}
                                    {query.dataSource1 === 'data_source1_webhook' && (
                                        <td className='templatesHandler-tbl-parmeter-webhook'>
                                            <input
                                                type='text'
                                                placeholder='Enter Webhook Path (e.g., lead.Name)'
                                                onChange={(e) => handleSelectChange(index, 'parameters_webhook_value', e.target.value)}
                                                value={query.parameters_webhook_value}
                                            />
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default TemplatesHandler;
