import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LookUp from '../General/Lookup';
import './ContactFilterRow.css'; // Import the CSS file

    const ContactFilterRow = ({ filter, onDelete, organization, onRowsDataChange, firstRow, secondRow ,excludedKeys}) => {
    const initialSelectedGroup = filter?.[0]?.selectedgroup || filter?.group || '';
    const initialSelectedOperator = filter?.[0]?.selectedoperator || filter?.operator || (filter?.index === 0 ? 'equals' : 'notEquals');

    const [rowsData, setRowsData] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(initialSelectedGroup);
    const [selectedOperator, setSelectedOperator] = useState(initialSelectedOperator);
    const [allKeys, setAllKeys] = useState([]);
    const availableKeys = allKeys.filter(key => !excludedKeys.includes(key));
    console.log(availableKeys);
    useEffect(() => {
        onRowsDataChange(rowsData);
    }, [rowsData]);

    useEffect(() => {
        const fetchData = async () => {
            let orgID = organization;
            try {
                const response = await axios.get('https://gambot.azurewebsites.net/api/Webhooks/GetAllKeys', {
                    params: {
                        organizationID: orgID
                    }
                });
                let keys = response.data;
                if (firstRow.length > 0 && secondRow.length === 0) {
                    const selectedValues = firstRow.flatMap(row => row.selectedgroup ? row.selectedgroup.map(group => group.value) : []);
                    keys = keys.filter(key => !selectedValues.includes(key));
                }
                setAllKeys(keys);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [organization]);
    const handleGroupChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedGroup(selectedOption.value);
            setRowsData(prevRowsData => {
                const updatedRow = { selectedgroup: selectedOption, selectedoperator: selectedOperator };
                return [updatedRow]; // Ensure only the current row data is updated
            });
        }
    };
    
    const handleOperatorChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOperator(selectedValue);
        setRowsData(prevRowsData => {
            const updatedRow = { selectedgroup: selectedGroup, selectedoperator: selectedValue };
            return [updatedRow]; // Ensure only the current row data is updated
        });
    };

    return (    
        <tr className='contactFilterRow-container'>
            <td className='contactFilterRow-cell'>
                <input type="text" readOnly value='Group' />
            </td>
            <td className='contactFilterRow-cell'>
                <select value={selectedOperator} onChange={handleOperatorChange}>
                    <option value="" disabled>Select Operator</option>
                    <option value="equals">Equals</option>
                    <option value="notEquals">Not Equals</option>
                </select>
            </td>
            <td className='contactFilterRow-cell lookup-cell'>
                <LookUp options={availableKeys.map(key => ({ value: key, label: key }))} defaultValue={selectedGroup} onChange={handleGroupChange} placeholder='Select Groups' allowAddNew={false} />
            </td>
            <td>
                <button className='contactFilterRow-delete-btn' onClick={onDelete}>Delete</button>
            </td>
        </tr>
    );
};

export default ContactFilterRow;