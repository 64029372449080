import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarData } from './SidebarData.js';
import './NavBarMenu.css';

function NavbarMenu() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="NavbarMenu">
      {/* כפתור המבורגר */}
      <div className="hamburger-menu" onClick={toggleMobileMenu}>
        <span className="icon-hamburger" style={{ display: isMobileMenuOpen ? 'none' : 'block' }}>☰</span>
        <span className="icon-close" style={{ display: isMobileMenuOpen ? 'block' : 'none' }}>✖</span>
      </div>

      {/* תפריט */}
      <ul className={`nav-menu-items ${isMobileMenuOpen ? 'mobile open' : 'desktop'}`}>
        {SidebarData.map((item, index) => (
          <li key={index} className={item.cName} style={{ '--i': index + 1 }}>
            <NavLink 
              to={item.path} 
              className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
              onClick={() => setMobileMenuOpen(false)} // סגירה לאחר לחיצה
            >
              {item.logo && (
                <img 
                  src={item.logo} 
                  alt={item.title}
                  className="menu-logo"
                />
              )}
              <span>{item.title}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default NavbarMenu;

// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { SidebarData } from './SidebarData.js';
// import './NavBarMenu.css';

// function NavbarMenu() {
//   return (
//     <div className="NavbarMenu">
//       <div className="nav-menu-items">
//         {SidebarData.map((item, index) => (
//           <li key={index} className={item.cName}>
//             <NavLink 
//               to={item.path} 
//               className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
//             >
//               {item.logo && (
//                 <img 
//                   src={item.logo} 
//                   alt={item.title}
//                   className="menu-logo"
//                 />
//               )}
//               <span>{item.title}</span>
//             </NavLink>
//           </li>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default NavbarMenu;