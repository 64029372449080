import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import './SendHttpRequestAction.css';

const SendHttpRequestAction = ({ steps, onChange, initialConfig = {} }) => {
  const [endpoint, setEndpoint] = useState(initialConfig.endpoint || '');
  const [method, setMethod] = useState(initialConfig.method || 'GET');
  const [headers, setHeaders] = useState(
    initialConfig.headers
      ? Object.entries(initialConfig.headers).map(([key, value]) => ({ key, value }))
      : [{ key: '', value: '' }]
  );
  const [bodyOption, setBodyOption] = useState(initialConfig.bodyOption || 'allAnswers');
  const [bodyBuildOption, setBodyBuildOption] = useState('steps'); // New State
  const [body, setBody] = useState(initialConfig.body || '');
  const [selectedQuestions, setSelectedQuestions] = useState(initialConfig.selectedQuestions || []);

  const initialLoad = useRef(true);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    const config = {
      endpoint,
      method,
      headers: headers.reduce((acc, { key, value }) => {
        if (key) acc[key] = value;
        return acc;
      }, {}),
      body:
        bodyOption === 'allAnswers'
          ? getAllAnswers()
          : bodyBuildOption === 'json'
          ? parseBody(body)
          : selectedQuestions,
      bodyOption,
      selectedQuestions: bodyBuildOption === 'steps' ? selectedQuestions : [],
    };

    onChange(config);
  }, [endpoint, method, headers, bodyOption, bodyBuildOption, body, selectedQuestions, onChange]);

  const parseBody = (bodyString) => {
    try {
      return JSON.parse(bodyString);
    } catch {
      return {};
    }
  };

  const getAllAnswers = () => {
    const answers = {};
    steps?.forEach((step) => {
      if (step?.type === 'trigger' && step?.action === 'IncomingMessage') {
        answers[step?.config?.question] = step?.config?.answer;
      }
    });
    return answers;
  };

  const handleSelectQuestions = (selectedOptions) => {
    setSelectedQuestions(selectedOptions);
  };

  const handleHeaderChange = (index, field, value) => {
    const newHeaders = [...headers];
    newHeaders[index][field] = value;
    setHeaders(newHeaders);
  };

  const handleAddHeader = () => {
    setHeaders([...headers, { key: '', value: '' }]);
  };

  const handleRemoveHeader = (index) => {
    setHeaders(headers.filter((_, i) => i !== index));
  };

  return (
    <div className="send-http-request-action">
      <label>
        Endpoint:
        <input
          className="send-http-request-action-endpoint"
          type="text"
          value={endpoint}
          onChange={(e) => setEndpoint(e.target.value)}
        />
      </label>
      <label>
        Method:
        <select value={method} onChange={(e) => setMethod(e.target.value)}>
          <option value="GET">GET</option>
          <option value="POST">POST</option>
          <option value="PUT">PUT</option>
          <option value="DELETE">DELETE</option>
        </select>
      </label>
      <label>
        Headers:
        {headers.map((header, index) => (
          <div key={index} className="send-http-request-action-header-row">
            <input
              type="text"
              placeholder="Key"
              value={header.key}
              onChange={(e) => handleHeaderChange(index, 'key', e.target.value)}
            />
            <input
              type="text"
              placeholder="Value"
              value={header.value}
              onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
            />
            <button
              className="send-http-request-action-remove"
              type="button"
              onClick={() => handleRemoveHeader(index)}
            >
              Remove
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddHeader}>
          Add Header
        </button>
      </label>
      <label>
        Body Option:
        <select value={bodyOption} onChange={(e) => setBodyOption(e.target.value)}>
          <option value="allAnswers">All Answers - Incoming & Sent Messages</option>
          <option value="manual">Build Manually</option>
        </select>
      </label>
      {bodyOption === 'manual' && (
        <div>
          <label>
            How to Build Body:
            <select
              value={bodyBuildOption}
              onChange={(e) => setBodyBuildOption(e.target.value)}
            >
              <option value="json">Build JSON Manually</option>
              <option value="steps">Pick from Steps</option>
            </select>
          </label>

          {bodyBuildOption === 'json' && (
            <div>
              <label>
                Body (JSON format):
                <textarea
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  placeholder='{"key": "value"}'
                />
              </label>
            </div>
          )}

{bodyBuildOption === 'steps' && (
  <div>
    <label>
      Select Questions to Include:
      <Select
        isMulti
        options={steps
          ?.filter(step => step.Action !== "SendHttpRequest" && step.action !== "SendHttpRequest")
          ?.map((step, index) => ({
            value: step.config?.question || `Step ${index + 1}`,
            label: step.config?.question || `Step ${index + 1}`,
          }))}
        onChange={handleSelectQuestions}
        value={selectedQuestions}
      />
    </label>
  </div>
)}

        </div>
      )}
    </div>
  );
};

export default SendHttpRequestAction;
