import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './FormFlowRunHistory.css';
import ScreenProgressIndicator from './ScreenProgressIndicator';
import AllAnswersModal from './AllAnswersModal'; 

const FormFlowRunHistory = ({ formFlowId, onClose, currenUser }) => {
    const [formFlowRuns, setFormFlowRuns] = useState([]);
    const [filteredFormFlowRuns, setFilteredFormFlowRuns] = useState([]);
    const [showAllAnswers, setShowAllAnswers] = useState(false);
    const [selectedFormData, setSelectedFormData] = useState(null);
    const [searchTerm, setSearchTerm] = useState(''); // State for search term

    const handleViewAllAnswersClick = (formData) => {
        setSelectedFormData(formData);
        setShowAllAnswers(true);
    };

    const handleCloseModal = () => {
        setShowAllAnswers(false);
        setSelectedFormData(null);
    };

    useEffect(() => {
        const fetchFormFlowRuns = async () => {
            try {
                const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetFormFlowRunsByFormFlowId', {
                    organization: currenUser.organization,
                    formFlowId
                });
    
                // Sort the formFlowRuns by createdOn in descending order (most recent first)
                const sortedFormFlowRuns = response.data.sort((a, b) => {
                    const dateA = new Date(a.createdOn);
                    const dateB = new Date(b.createdOn);
                    return dateB - dateA; // Sort descending (newest first)
                });
    
                setFormFlowRuns(sortedFormFlowRuns);
                setFilteredFormFlowRuns(sortedFormFlowRuns); // Initially show all runs
            } catch (error) {
                console.error("Error fetching form flow runs:", error);
            }
        };
    
        fetchFormFlowRuns();
    }, [formFlowId]);

    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        // Filter formFlowRuns based on contactPhoneNumber
        const filtered = formFlowRuns.filter(run => 
            run?.contactPhoneNumber?.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredFormFlowRuns(filtered);
    };

    return (
        <>
            <div className="form-flow-run-history-modal">
                <div className="form-flow-run-history-modal-content">
                    <h2>Form Flow Run History</h2>
                    <button className="form-flow-run-history-modal-close-button" onClick={onClose}>X</button>
                    
                    {/* Search input */}
                    <div className="form-flow-run-history-search">
                        <input
                            type="text"
                            placeholder="Search by contact phone number"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>

                    <table className="form-flow-run-history-modal-history-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>Status</th>
                                <th>Started At</th>
                                <th>Completed At</th>
                                <th>Progress</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredFormFlowRuns?.map((run) => (
                                <tr key={run?.Id}>
                                    <td>{run?.contactPhoneNumber}</td>
                                    <td>{run?.contactPhoneNumber}</td>
                                    <td>{run?.Status === "completed" ? "Completed" : "In Progress"}</td>
                                    <td>{new Date(run?.StartedAt).toLocaleString()}</td>
                                    <td>
                                        {run?.CompletedAt ? (
                                            <>
                                                {new Date(run.CompletedAt).toLocaleString()}
                                                <button
                                                    onClick={() => handleViewAllAnswersClick(run.FormData)}
                                                    className="view-all-answers-button"
                                                >
                                                    View All Answers
                                                </button>
                                            </>
                                        ) : (
                                            "—"
                                        )}
                                    </td>
                                    <td>
                                        <ScreenProgressIndicator 
                                            totalScreens={run?.totalScreenNumber} 
                                            screenProgress={run?.ScreenProgress} 
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    
                    {showAllAnswers && selectedFormData && (
                        <AllAnswersModal formData={selectedFormData} onClose={handleCloseModal} />
                    )}
                </div>
            </div>
        </>
    );
};

export default FormFlowRunHistory;
