import React, { useState, useEffect } from 'react';
import './MessagePreview.css'; // Import the CSS file
import LocationIcon from '@mui/icons-material/LocationOn';

const MessagePreview = ({
  headerType,
  mediaType,
  mediaContent,
  mediaUrl,
  variables,
  footerText,
  variableSamples,
  bodyContent,
  buttons,
  headerTxt
}) => {
  const [showAllButtons, setShowAllButtons] = useState(false);
  const [language, setLanguage] = useState('en'); // Default to English

  useEffect(() => {
    detectLanguage(bodyContent);
  }, [bodyContent]);

  const detectLanguage = (text) => {
    const hebrewPattern = /[\u0590-\u05FF]/;
    if (hebrewPattern.test(text)) {
      setLanguage('he');
    } else {
      setLanguage('en');
    }
  };

  const renderBodyText = () => {
    let previewText = bodyContent;
    variables.forEach((variable) => {
      const sample = variableSamples[variable];
      const variableRegex = new RegExp(`{{${variable}}}`, 'g');
      previewText = previewText.replace(variableRegex, sample);
    });
    return previewText;
  };

  const toggleShowAllButtons = () => {
    setShowAllButtons(!showAllButtons);
  };
  const locationString = `{{Location Name}}`
  const addressString = `{{Address}}`
  const renderMediaContent = () => {
    if (mediaType && (mediaType?.startsWith('image/') || mediaType === 'image')) {
      return <img src={mediaUrl} alt="Image" className="media-content-image" />;
    } else if (mediaType?.startsWith('video') || mediaType === 'video') {
      return (
        <div className="media-content">
          <video src={mediaUrl} controls className="media-content-video">
            Your browser does not support the video tag.
          </video>
        </div>
      );
    } else if (mediaType?.startsWith('application/') || mediaType === 'document') {
      return <a href={mediaUrl} target="_blank" rel="noopener noreferrer">View Document</a>;  
    } else if (mediaType === 'location') {
      return (
        <div className="location-content" style={{ direction: 'ltr', textAlign: 'left' }}>
          <LocationIcon/>
          <div  className="location-details">
            <p>{locationString}</p>
            <p>{addressString}</p>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const formattedBodyContent = bodyContent.replace(/\n/g, '<br />');
  const direction = language === 'he' ? 'rtl' : 'ltr';
  const textAlign = language === 'he' ? 'right' : 'left';
  useEffect(() => {
    renderMediaContent(); // Ensure the media content is updated when mediaUrl changes
  }, [mediaUrl]);  // Dependency on mediaUrl ensures it's called when mediaUrl changes
  
  return (
    <div className="message-preview-container" style={{ direction, textAlign }}>
      <h3 style={{textAlign:'center'}} >Message Preview</h3>
      <div className="message-preview">
        {/* Header */}
        {headerType === 'text' && <div className="header-text">{headerTxt}</div>}
        {headerType === 'media' && (
          <div className="media-content">
            {renderMediaContent()}
          </div>
        )}

        {/* Body */}
        <p className="body-text" dangerouslySetInnerHTML={{ __html: formattedBodyContent }} />
        <div className="footer-text">{footerText}</div>

        {/* Buttons */}
        <div className="buttons-container">
          {buttons !== undefined
            ? buttons.slice(0, showAllButtons ? buttons.length : 2).map((button, index) => (
                <button key={index} className="message-button">
                  {button.label? button?.label : button?.text}
                </button>
              ))
            : ''}
          {buttons !== undefined && buttons.length > 2 && (
            <button onClick={toggleShowAllButtons} className="message-button">
              {showAllButtons ? 'See Less' : 'See All Options'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessagePreview;
