import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TemplatesHandler from './TemplatesHandler';
import './startConversationByTemplate.css';

const StartConversationByTemplate = ({ currentUser, onClose , contactPhoneNumber }) => {
    const [template, setTemplate] = useState(null);
    const [templateVariables, setTemplateVariables] = useState({});
    const [queries, setQueries] = useState([]);
    const [locationName, setLocationName] = useState('');
    const [locationAddress, setLocationAddress] = useState('');
    const [longitude, setLongitude] = useState('');
    const [latitude, setLatitude] = useState('');

    const handleTemplatesVariableChange = (template, variables, queries) => {
        setTemplate(template);
        setTemplateVariables(variables);
        setQueries(queries);
        console.log("Templates variable changed:", { template, variables, queries });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const payload = {
            organization: currentUser.organization,
            templateMessageData: {
                PhoneNumber: contactPhoneNumber, // Assuming you have this info
                TemplateId: template ? template.Id : null, // Adjust according to your data structure
                SentById: currentUser.userId,
                TemplateVariableQuery: queries,
                LocationDetails: {
                    locationName,
                    locationAddress,
                    longitude,
                    latitude
                }
            }
        };

        try {
            const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/SendTemplateMessage', payload);
            console.log("Template message sent:", response.data);
            onClose();
        } catch (error) {
            console.error('Error sending template message:', error);
        }
    };

    return (
        <div className='start-conversation-by-template'>
            <h2>Start Conversation by Template</h2>
            <button className="start-conversation-by-template-close-popup" onClick={onClose}>&times;</button>
            <form onSubmit={handleSubmit}>                
                <TemplatesHandler
                    organization={currentUser.organization}
                    onTemplatesVariableChange={handleTemplatesVariableChange}
                    templateId={null}
                    templateVariableQuery={[]}
                    recipientSource={'Contacts'}
                    locationName={locationName}
                    locationAddress={locationAddress}
                    longitude={longitude}
                    latitude={latitude}
                    setLocationName={setLocationName}
                    setLocationAddress={setLocationAddress}
                    setLongitude={setLongitude}
                    setLatitude={setLatitude}
                    parent={'startConversationByTemplate'}
                />
                <button type="submit">Submit</button>
                <button type="button" onClick={onClose}>Cancel</button>
            </form>
        </div>
    );
};

export default StartConversationByTemplate;
