import React from 'react';
import './ChatTimelineMessage.css';
import { IoIosNotifications } from "react-icons/io";

const ChatTimelineMessage = ({ timelineMessage, createdOn, currentUser , item }) => {
    const buildTimelineMessage = (item) => {
        switch (item?.TimelineType) {
            case 'assign':
                return `Assigned to: ${item?.assignToName || 'Unknown'} by: ${item?.createdByName || 'Unknown'}`;
            case 'open conversation':
                return `Opened conversation by: ${item?.createdByName || 'Unknown'}`;
            case 'note':
                return `Note added: ${item?.note || 'No details provided'}`;
            case 'status change':
                return `${item?.note || 'No details provided'} by: ${item?.createdByName || 'Unknown'}`;
            case 'close':
                return `Conversation closed by: ${item?.createdByName || 'Unknown'}`;
    
            case 'botomation send message': // New case for Botomation Send Message
                return `Botomation message sent with template name: ${item?.note || 'No Template ID'}`;
    
            case 'external webhook request creates message': // New case for External Webhook Request
            return `External Webhook request created template name: ${item?.note || 'No Template'}`;
    
            case 'start conversation manually': // New case for Start Conversation
            return `Start Conversation manually -> Sent template name: ${item?.note || 'No Template ID'}`;
    
            case 'campaign message sent': // New case for Campaign Message Sent
                return `Campaign ${item?.note} message sent by: ${item?.sentByName || 'Unknown'}`;
            case 'category change': // New case for Category Change
                return `${item?.note || 'No details provided'} by: ${item?.createdByName || 'Unknown'}`;
            default:
                return `Timeline event: ${item?.TimelineType || 'Unknown'} by: ${item?.createdByName || 'Unknown'}`;
        }
    };
    
    
    
    
    const formatToUserTimezone = (utcDate, timezone) => {
        if (!utcDate) return 'No date available';
        try {
            return new Intl.DateTimeFormat('en-GB', {
                timeZone: timezone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            }).format(new Date(utcDate));
        } catch (error) {
            console.error('Error formatting date:', error, 'Timezone:', timezone);
            return 'Invalid date';
        }
    };

    return (
        <div className="chat-timeline-message">
            <p>
            <IoIosNotifications></IoIosNotifications>
            {buildTimelineMessage(item)} {timelineMessage}</p>
            <p className="chat-timeline-message-timestamp">
                {createdOn ? formatToUserTimezone(createdOn, currentUser?.timeZone) : 'No Time Available'}
            </p>
        </div>
    );
};

export default ChatTimelineMessage;
