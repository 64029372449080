import React, { useState, useEffect } from 'react';
import TemplatesHandler from '../../Templates/TemplatesHandler';
import './OutboundMessageTrigger.css'

const OutboundMessageTrigger = ({ organization, onChange, initialConfig = {} }) => {
  const [messageType, setMessageType] = useState(initialConfig.messageType || 'regular');
  const [templateId, setTemplateId] = useState(initialConfig.templateId || null);
  const [templateVariableQuery, setTemplateVariableQuery] = useState(initialConfig.templateVariableQuery || []);
  const [locationName, setLocationName] = useState(initialConfig.locationName || '');
  const [locationAddress, setLocationAddress] = useState(initialConfig.locationAddress || '');
  const [longitude, setLongitude] = useState(initialConfig.longitude || '');
  const [latitude, setLatitude] = useState(initialConfig.latitude || '');
  const [messageContent, setMessageContent] = useState(initialConfig.messageContent || '');
  const [templateConfig, setTemplateConfig] = useState(initialConfig.templateConfig || {});

  useEffect(() => {
    onChange({ 
      messageType, 
      templateId, 
      templateVariableQuery, 
      locationName, 
      locationAddress, 
      longitude, 
      latitude 
    });
  }, [messageType, templateId, templateVariableQuery, locationName, locationAddress, longitude, latitude, onChange]);

  const handleMessageTypeChange = (e) => {
    setMessageType(e.target.value);
    onChange({ 
      messageType: e.target.value, 
      templateId, 
      templateVariableQuery, 
      locationName, 
      locationAddress, 
      longitude, 
      latitude 
    });
  };

  const handleTemplatesVariableChange = (template, variables, queries) => {
    setTemplateId(template?.id);  
    setTemplateVariableQuery(queries);
    onChange({ 
      messageType, 
      templateId: template?.id, 
      templateVariableQuery: queries, 
      locationName, 
      locationAddress, 
      longitude, 
      latitude 
    });
  };

  const handleMessageChange = (e) => {
    onChange({ 
      messageType, 
      message: e.target.value, 
      templateId, 
      templateVariableQuery, 
      locationName, 
      locationAddress, 
      longitude, 
      latitude 
    });
  };

  return (
    <div className="outbound-message-trigger">
      <label>
        Message Type:
        <select value={messageType} onChange={handleMessageTypeChange}>
          <option value="regular">Regular</option>
          <option value="template">Template</option>
        </select>
      </label>

      {/* {messageType === 'regular' && (
        <div>
          <label>
            Message:
            <input 
              type="text" 
              value={initialConfig.message || ''} 
              onChange={handleMessageChange} 
            />
          </label>
        </div>
      )} */}
   {messageType === 'regular' && (
        <div>
          <label>
            Message:
            <textarea
              className="message-textarea"
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
              placeholder="Enter your message here"
            />
          </label>
        </div>
      )}
      {messageType === 'template' && (
        <TemplatesHandler
          organization={organization}
          onTemplatesVariableChange={handleTemplatesVariableChange}
          templateId={templateId}
          templateVariableQuery={templateVariableQuery}
          recipientSource={'Contacts'}
          locationName={locationName}
          locationAddress={locationAddress}
          longitude={longitude}
          latitude={latitude}
          setLocationName={setLocationName}
          setLocationAddress={setLocationAddress}
          setLongitude={setLongitude}
          setLatitude={setLatitude}
        />
      )}
    </div>
  );
};

export default OutboundMessageTrigger;
