import React, { useState, useEffect } from 'react';
import './ContactsKeys.css';

const ContactsKeys = ({ onChange, initialTags = [], className }) => {
  const [tags, setTags] = useState(initialTags);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setTags(initialTags);
  }, [initialTags]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue) {
      e.preventDefault(); // Prevent form submission
      const newTags = [...tags, inputValue];
      setTags(newTags);
      setInputValue('');
      onChange(newTags);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const removeTag = (indexToRemove) => {
    const newTags = tags.filter((_, index) => index !== indexToRemove);
    setTags(newTags);
    onChange(newTags);
  };

  return (
    <div className={`tag-input-container ${className}`}>
      {tags.map((tag, index) => (
        <div key={index} className="tag">
          {tag}
          <button type="button" className="remove-button" onClick={() => removeTag(index)}>
            x
          </button>
        </div>
      ))}
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type and press Enter"
        className="tag-input"
      />
    </div>
  );
};


export default ContactsKeys;
