import React from 'react';
import '../../components/General/Home.css'
import Menu from '../General/Menu';
import ChatPage from '../Chats/ChatPage';

function Home({ currentUser, signOut }) {
    return (
        <div className='home'>
            <div className='home-container'>
                {/* <Menu /> */}
                <ChatPage currentUser={currentUser} signOut={signOut} />
            </div>
        </div>
    );
}

export default Home;
