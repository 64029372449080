import React, { useState, useRef, useEffect } from 'react';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import './BodyText.css';

const BodyText = ({ bodyText, variables, onAddSample, onVariableSampleChange }) => {
    const [textAreaContent, setTextAreaContent] = useState(bodyText || '');
    const textAreaRef = useRef(null);
    const [showEmojis, setShowEmojis] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const maxLength = 1023;

    useEffect(() => {
        setTextAreaContent(bodyText);
    }, [bodyText]);

    useEffect(() => {
        if (variables.length > 0) {
            addVariableToTextArea(variables[variables.length - 1]);
        }
    }, [variables]);

    const handleEmojiSelect = (emoji) => {
        addEmojiToTextArea(emoji.native);
    };

    const addEmojiToTextArea = (emoji) => {
        insertTextAtCursor(emoji);
    };

    const addVariableToTextArea = (variable) => {
        insertTextAtCursor(`{{${variable}}}`);
    };

    const insertTextAtCursor = (text) => {
        if (!textAreaRef.current) return;
        if (isChanged === true) {
            const cursorPosition = textAreaRef.current.selectionStart;
            const textBeforeCursor = textAreaContent.substring(0, cursorPosition);
            const textAfterCursor = textAreaContent.substring(cursorPosition);
            const newText = textBeforeCursor + text + textAfterCursor;

            setTextAreaContent(newText);
            onAddSample(newText);

            textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd = cursorPosition + text.length;
            textAreaRef.current.focus();
        }
    };

    const toggleEmojiPicker = () => {
        setShowEmojis(!showEmojis);
    };

    const handleTextAreaChange = (e) => {
        const newText = e.target.value;
        setTextAreaContent(newText);
        onAddSample(newText);
        setIsChanged(true);
    };

    const remainingChars = maxLength - textAreaContent.length;

    const handleFormatText = (openTag, closeTag) => {
        const selectedText = window.getSelection().toString();
        if (!selectedText) return;

        // Add the tags around selected text
        const newText = textAreaContent.replace(selectedText, `${openTag}${selectedText}${closeTag}`);
        setTextAreaContent(newText);
        onAddSample(newText);
        setIsChanged(true);
    };

    return (
        <div className="editor-container">
            <textarea
                ref={textAreaRef}
                value={textAreaContent}
                className="editor-textarea"
                onChange={handleTextAreaChange}
                placeholder="Enter your message here..."
                style={{ width: '100%', minHeight: '100px', marginBottom: '10px' }}
                maxLength={maxLength}
            />

            <div className="toolbar-bodytext">
                <button onClick={toggleEmojiPicker}>😀</button>
                <button onClick={() => handleFormatText('<b>', '</b>')}><b>B</b></button>
                <button onClick={() => handleFormatText('<i>', '</i>')}><i>I</i></button>
                <button onClick={() => handleFormatText('<s>', '</s>')}><s>abc</s></button>
                <button onClick={() => handleFormatText('<code>', '</code>')}><code>{'{ }'}</code></button>
            </div>

            {/* Emoji Picker */}
            {showEmojis && (
                <div className="emoji-picker active">
                    <Picker
                        data={data}
                        onEmojiSelect={handleEmojiSelect}
                        set="apple"
                    />
                </div>
            )}

            {/* Max Length Info */}
            <p className="max-length-info">
                {remainingChars >= 0 
                  ? `${remainingChars} characters remaining (max: 1023)` 
                  : `Maximum character limit of 1023 exceeded!`}
            </p>
        </div>
    );
};

export default BodyText;
