class WebSocketInstance {
    constructor(organization, phoneNumber = null) {
        let url = `wss://gambot.azurewebsites.net/FirebaseWebsocketHandler.ashx?organization=${organization}`;
        if (phoneNumber) {
            url += `&chatId=${phoneNumber}`;
        }
        this.websocket = new WebSocket(url);

        this.websocket.addEventListener('open', (event) => {
            console.log('WebSocket connection opened:', event);
        });

        this.websocket.addEventListener('error', (event) => {
            console.error('WebSocket error:', event);
            console.log('WebSocket ready state:', this.websocket.readyState);
        });
    }

    send(message) {
        return new Promise((resolve, reject) => {
            if (this.websocket.readyState === WebSocket.OPEN) {
                this.websocket.send(JSON.stringify(message));
                resolve();
            } else {
                reject(new Error('WebSocket connection is not open.'));
            }
        });
    }

    close() {
        this.websocket.close();
    }

    addEventListener(eventType, callback) {
        this.websocket.addEventListener(eventType, callback);
    }

    removeEventListener(eventType, callback) {
        this.websocket.removeEventListener(eventType, callback);
    }
}

export default WebSocketInstance;


// class WebSocketInstance {
//     constructor(organization, phoneNumber = null) {
//         let url = `wss://gambot.azurewebsites.net/FirebaseWebsocketHandler.ashx?organization=${organization}`;
//         if (phoneNumber) {
//             url += `&chatId=${phoneNumber}`;
//         }
//         this.websocket = new WebSocket(url);

//         this.websocket.addEventListener('open', (event) => {
//             console.log('WebSocket connection opened:', event);
//         });

//         this.websocket.addEventListener('error', (event) => {
//             console.error('WebSocket error:', event);
//             console.log('WebSocket ready state:', this.websocket.readyState);
//         });
//     }

//     send(message) {
//         return new Promise((resolve, reject) => {
//             if (this.websocket.readyState === WebSocket.OPEN) {
//                 this.websocket.send(JSON.stringify(message));
//                 resolve();
//             } else {
//                 reject(new Error('WebSocket connection is not open.'));
//             }
//         });
//     }

//     close() {
//         this.websocket.close();
//     }

//     addEventListener(eventType, callback) {
//         this.websocket.addEventListener(eventType, callback);
//     }

//     removeEventListener(eventType, callback) {
//         this.websocket.removeEventListener(eventType, callback);
//     }
// }

// export default WebSocketInstance;
