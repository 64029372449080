import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MessagePreview from '../../components/Templates/MessagePreview';
import axios from 'axios';
import './PreviewRun.css';

const PreviewRun = ({
  open,
  onClose,
  onConfirm,
  recipients,
  onRemoveRecipient,
  campaign,
  organization,
}) => {
  const [search, setSearch] = useState('');
  const [templatePreview, setTemplatePreview] = useState(null);
  const [regularMessage, setRegularMessage] = useState('');
  const [mediaUrl, setMediaUrl] = useState(null);

  const [isRecipientChecked, setIsRecipientChecked] = useState(false);
  const [isPreviewChecked, setIsPreviewChecked] = useState(false);

  const displayedRecipients = search
    ? recipients.filter(
        (recipient) =>
          recipient.name.toLowerCase().includes(search.toLowerCase()) ||
          recipient.phoneNumber.includes(search)
      )
    : recipients;

    const getGmbtMediaUrlByTemplateId = async (templateId) => {
      try {
        const response = await axios.post(
          'https://gambot.azurewebsites.net/api/webhooks/GetMediaByTemplateId',
          {
            organization: organization,
            templateId: templateId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        return encodeURI(response.data.mediaUrl); // Encode and return the URL
      } catch (error) {
        console.error('Error fetching media URL:', error);
        return ''; // Return empty string if there's an error
      }
    };
    

  useEffect(() => {
    const fetchTemplatePreview = async () => {
      if (!campaign) return;
  
      if (campaign.messageType === 'Regular') {
        setRegularMessage(campaign.message);
      } else if (campaign.messageType === 'Template' && campaign.wabaTemplateId) {
        try {
          const response = await axios.post(
            'https://gambot.azurewebsites.net/api/Webhooks/GetTemplateById',
            {
              organizationId: organization,
              templateId: campaign.wabaTemplateId,
            }
          );
  
          const data = response.data;
          if (data) {
            const bodyComponent = data.components.find(
              (component) => component.type === 'BODY'
            );
            const footerComponent = data.components.find(
              (component) => component.type === 'FOOTER'
            );
            const buttonsComponent = data.components.find(
              (component) => component.type === 'BUTTONS'
            );
            const headerComponent = data.components.find(
              (component) => component.type === 'HEADER'
            );
  
            let headerType = 'text';
            let mediaType = null;
            let mediaUrlFetched = '';
  
            if (headerComponent) {
              switch (headerComponent.format) {
                case 'IMAGE':
                  headerType = 'media';
                  mediaType = 'image';
                  mediaUrlFetched = await getGmbtMediaUrlByTemplateId(campaign.wabaTemplateId);
                  break;
                case 'VIDEO':
                  headerType = 'media';
                  mediaType = 'video';
                  mediaUrlFetched = await getGmbtMediaUrlByTemplateId(campaign.wabaTemplateId);
                  break;
                case 'DOCUMENT':
                  headerType = 'media';
                  mediaType = 'document';
                  mediaUrlFetched = await getGmbtMediaUrlByTemplateId(campaign.wabaTemplateId);
                  break;
                case 'TEXT':
                default:
                  headerType = 'text';
                  mediaType = null;
                  break;
              }
            }
            
  
            setTemplatePreview({
              headerType: headerType,
              mediaType: mediaType,
              mediaContent: null,
              mediaUrl: mediaUrlFetched || '',
              variables: bodyComponent?.example?.body_text[0] || [],
              footerText: footerComponent?.text || '',
              variableSamples: {},
              bodyContent: bodyComponent?.text || '',
              buttons: buttonsComponent?.buttons || [],
              headerTxt: data.name || '',
            });
          }
        } catch (error) {
          console.error('Error fetching template preview:', error);
        }
      }
    };
  
    fetchTemplatePreview();
  }, [campaign, organization]);
  

  return (
    <div className="previewRun-Container">
      <Dialog
        className="previewRun-Container-dialog"
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="previewRun-Container-dialog-title">
          Confirm Message & Recipients
        </DialogTitle>
        <div className="previewRun-ContentContainer">
          {/* Contact List on the Left */}
          <div className="previewRun-RecipientTabl">
            <DialogContent>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isRecipientChecked}
                    onChange={(e) => setIsRecipientChecked(e.target.checked)}
                  />
                }
                label="Confirm Recipients"
              />
              <TextField
                label="Search by Name or Phone Number"
                variant="outlined"
                fullWidth
                margin="normal"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Table className="previewRun-Container-dialog-tbl">
                <TableHead>
                  <TableRow>
                    <TableCell>Recipient Name</TableCell>
                    <TableCell>Recipient Phone</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedRecipients.map((recipient, index) => (
                    <TableRow key={index} className="previewRun-Container-row">
                      <TableCell>{recipient.name}</TableCell>
                      <TableCell>{recipient.phoneNumber}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => onRemoveRecipient(recipient.id)}
                          size="small"
                          className="previewRun-Container-deleteButton"
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </DialogContent>
          </div>

          {/* Message Preview on the Right */}
          <div className="template-preview-container">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPreviewChecked}
                  onChange={(e) => setIsPreviewChecked(e.target.checked)}
                />
              }
              label="Confirm Message Preview"
            />
            {campaign?.messageType === 'Regular' && regularMessage && (
              <div className="message-preview-container">
                <h3>Message Preview</h3>
                <p>{regularMessage}</p>
              </div>
            )}
            {campaign?.messageType === 'Template' && templatePreview && (
              <MessagePreview
                headerType={templatePreview.headerType}
                mediaType={templatePreview.mediaType}
                mediaContent={templatePreview.mediaContent}
                mediaUrl={templatePreview.mediaUrl}
                variables={templatePreview.variables}
                footerText={templatePreview.footerText}
                variableSamples={templatePreview.variableSamples}
                bodyContent={templatePreview.bodyContent}
                buttons={templatePreview.buttons}
                headerTxt={templatePreview.headerTxt}
              />
            )}
          </div>
        </div>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={onConfirm}
            color="primary"
            disabled={!isRecipientChecked || !isPreviewChecked}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PreviewRun;
