import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import './Integrations.css';
import { useHistory } from 'react-router-dom';
import ConfirmationDialog from '../General/ConfirmationDialog'

const Integrations = (currentUser) => {
    console.log(currentUser);
  const navigate = useNavigate();
  const [integrations, setIntegrations] = useState([]); // State for integrations data
  const [openDialog, setOpenDialog] = useState(false); // State for integration details dialog
  const [selectedIntegration, setSelectedIntegration] = useState(null); // State for the selected integration
  const [integrationName, setIntegrationName] = useState(''); // State for the integration name
  const [status, setStatus] = useState(''); // State for the integration status
  const [integrationType, setIntegrationType] = useState(''); // State for the integration type

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://gambot.azurewebsites.net/api/Webhooks/GetAllIntegrations', {
            params: {
              organizationID: currentUser.currentUser.organization
            }
          });
        console.log(response.data);
        setIntegrations(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleDeleteClick = (integration) => {
    console.log(integration);
    setSelectedIntegration(integration);
    setOpenDialog(true);
  };
  const handleNewIntegration = ()=>{
    const thisIntegrationQueryString = new URLSearchParams(
        {
            "organization": currentUser.currentUser.organization,
            "userEmail" : currentUser.currentUser.Email,
            "mode" : "Create"
        }
    )
    navigate(`/NewIntegration/?${thisIntegrationQueryString}`);

  }
  const handleEditClick = (integration) => {
    const thisIntegrationQueryString = new URLSearchParams({
      "organization": currentUser.currentUser.organization,
      ...integration
    })
    navigate(`/UpdateIntegration/?${thisIntegrationQueryString}`);
  };

  
  const handleConfirmDelete = () => {
    console.log(selectedIntegration);
    // Call your delete integration API here with selectedIntegration.id
    var payload = {
      "organization": currentUser.currentUser.organization,
      "integrationId": selectedIntegration.IntegrationId
      
    }
    console.log(payload);
    axios.post("https://gambot.azurewebsites.net/api/Webhooks/DeleteIntegration", payload)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
    setOpenDialog(false);
  };

  // Handle dialog close
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Handle integration name change
  const handleIntegrationNameChange = (event) => {
    setIntegrationName(event.target.value);
  };

  // Handle status change
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  // Handle integration type change
  const handleIntegrationTypeChange = (event) => {
    setIntegrationType(event.target.value);
  };

  return (
    <div className="integration-list-container">
      <h1 className="integration-list-header">Integrations</h1>
      {/* Add a search input if needed */}
      {/* <input type="text" placeholder="Search Integrations" className="search-input" /> */}
      <div className='integration-list-table-container'>
        <table className="integration-list-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Type</th>
              <th>Database</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {integrations.map((integration) => (
              <tr key={integration.id}>
                <td>{integration.name}</td>
                <td>{integration.status}</td>
                <td>{integration.type}</td>
                <td>{integration.database}</td>

                {/* Add action buttons for editing and deleting integrations */}
                <td>
                  <button className="integration-edit-button" onClick={() => handleEditClick(integration)}>Edit</button>
                  <button className="integration-delete-button" onClick={() => handleDeleteClick(integration)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirmDelete}
         message={`Are you sure you want to delete the flow: "${selectedIntegration?.name}"?`}
        actionType='Delete'
      />
      <div className='integrations-action-buttons'>
      <button className="add-new-integration-button" onClick={()=> handleNewIntegration()}>Add New Integration</button>
        {/* <Link to="/NewIntegration">
          <button className="add-new-integration-button">Add New Integration</button>
        </Link> */}
      </div>
    </div>
  );
};

export default Integrations;
