import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './WorkflowDesigner.css';
import IncomingMessageTrigger from './IncomingMessageTrigger';
import OutboundMessageTrigger from './OutboundMessageTrigger';
import Scheduled from '../../General/Scheduled';
import ConditionAction from './ConditionAction';
import OutboundMessageAction from './OutboundMessageAction';
import SendHttpRequestAction from './SendHttpRequestAction';
import WebhookTrigger from './WebhookTrigger';
import { FaTrash, FaChevronDown, FaChevronUp, FaEnvelope, FaPaperPlane, FaGlobe, FaQuestionCircle } from 'react-icons/fa';
import GambotAction from './GambotAction';


const deepEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};


const WorkflowDesigner = ({ currentUser, steps, setSteps }) => {
  const [expanded, setExpanded] = useState({});
  const yesActionInProgress = useRef(false);  // Flag for "if yes" actions
  const noActionInProgress = useRef(false);   // Flag for "if no" actions
  // Utility function to normalize step properties
  const normalizeStep = (step) => ({
    ...step,
    action: step.Action || step.action,
    config: step.Config || step.config,
    type: step.Type || step.type,
  });

  // Normalize all steps
  const normalizedSteps = steps.map(normalizeStep);

  const handleAddTrigger = () => {
    setSteps([...steps, { type: 'trigger', action: null, config: {} }]);
    setExpanded({ [steps.length]: true });
  };

  const handleAddAction = (index) => {
    const newSteps = [...steps];
    newSteps.splice(index + 1, 0, { type: 'action', action: null, config: {} });
    setSteps(newSteps);
    setExpanded({ [index + 1]: true });
  };

  const handleSelectAction = (index, action) => {
    const newSteps = [...steps];
    newSteps[index].action = action;
    setSteps(newSteps);
    setExpanded({ [index]: true });
  };

  const handleDeleteAction = (index) => {
    const newSteps = steps.filter((_, i) => i !== index);
    setSteps(newSteps);
    const newExpanded = { ...expanded };
    delete newExpanded[index];
    setExpanded(newExpanded);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedSteps = Array.from(steps);
    const [movedStep] = reorderedSteps.splice(result.source.index, 1);
    reorderedSteps.splice(result.destination.index, 0, movedStep);

    setSteps(reorderedSteps);
  };

  const handleTriggerConfigChange = (index, config) => {
    const newSteps = [...steps];
    newSteps[index].config = config;
    setSteps(newSteps);
  };

  const handleActionConfigChange = (index, config) => {
    const newSteps = [...steps];
    newSteps[index].config = config;
    setSteps(newSteps);
  };
  

  const handleHttpRequestResponse = (index, config) => {
    const newSteps = [...steps];
    newSteps[index].config = config;
    setSteps(newSteps);
  };

  const toggleExpand = (index) => {
    setExpanded((prevExpanded) => ({ ...prevExpanded, [index]: !prevExpanded[index] }));
  };

  const getButtonLabelsAndTemplateIds = (steps) => {
    const buttonLabels = [];
    const templateIds = [];

    const processSteps = (stepList) => {
      stepList.forEach((step) => {
        if (step.type === 'action' && step.action === 'SendMessage' && step.config?.templateConfig?.template?.components) {
          const templateName = step.config.templateConfig.template.name || 'Unnamed Template';
          const templateId = step.config.templateConfig.template.Id;
          step.config.templateConfig.template.components.forEach((component) => {
            if (component.type === 'BUTTONS' && component.buttons) {
              component.buttons.forEach((button) => {
                if (button.text) {
                  buttonLabels.push(templateName + " + " + button.text);
                  templateIds.push(templateId);
                }
              });
            }
          });
        }
        if (step.type === 'action' && step.action === 'Condition') {
          processSteps(step.config?.yesActions || []);
          processSteps(step.config?.noActions || []);
        }
      });
    };

    processSteps(steps);

    return { buttonLabels, templateIds };
  };
  const formatActionName = (action) => {
    if (!action) return '';
    return action
      .replace(/([A-Z])/g, ' $1')  // Add a space before each uppercase letter
      .replace(/(^\w|\s\w)/g, m => m.toUpperCase())  // Capitalize the first letter of each word
      .trim();  // Remove any leading/trailing whitespace
  };

  const getActionIcon = (action) => {
    switch(action) {
      case 'IncomingMessage':
        return <FaEnvelope />;
      case 'SendMessage':
        return <FaPaperPlane />;
      case 'SendHttpRequest':
        return <FaGlobe />;
      case 'Condition':
        return <FaQuestionCircle />;
      default:
        return null;
    }
  };
  console.log('aa');
  console.log(steps);
  return (
    <div className="workflow-designer">
      {steps.length === 0 ? (
        <button onClick={handleAddTrigger} className="add-trigger-button" type="button">+ Add Trigger</button>
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="workflow-steps">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps} className="workflow-steps-container">
                {normalizedSteps.map((step, index) => (
                  <Draggable key={index} draggableId={index.toString()} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="workflow-step"
                      >
                        <div className="step-controls">
                          <div className="expand-delete-container">
                            <button onClick={() => toggleExpand(index)} className="expand-button" type="button">
                              {expanded[index] ? <FaChevronUp /> : <FaChevronDown />}
                            </button>
                          </div>
                          <span className="step-header">
                            {step.type === 'trigger'
                              ? (step.action ? formatActionName(step.action) : 'Select Trigger')
                              : (step.action ? formatActionName(step.action) : 'Select Action')}
                          </span>
                          <FaTrash onClick={() => handleDeleteAction(index)} className="delete-action-button" type="button" />
                        </div>
                        {expanded[index] && (
                          <div className="step-content">
                            {step.type === 'trigger' ? (
                              <div className="trigger-selector">
                                <h3>Select Trigger</h3>
                                <select
                                  onChange={(e) => handleSelectAction(index, e.target.value)}
                                  value={step.action || ''}
                                >
                                  <option value="">Select Trigger Type</option>
                                  <option value="IncomingMessage">Incoming Message</option>
                                  <option value="OutboundMessage">Outbound Message</option>
                                  <option value="Scheduled">Scheduled</option>
                                  <option value="Reminder">Reminder</option>
                                  <option value="DbOperation">DB Operation</option>
                                  <option value="webhook">Webhook</option>
                                </select>
                                {step.action === 'IncomingMessage' && (
                                  <IncomingMessageTrigger
                                    organization={currentUser?.organization}
                                    onChange={(config) => handleTriggerConfigChange(index, config)}
                                    initialConfig={step.config}
                                  />
                                )}
                                {step.action === 'OutboundMessage' && (
                                  <OutboundMessageTrigger
                                    organization={currentUser?.organization}
                                    onChange={(config) => handleTriggerConfigChange(index, config)}
                                    initialConfig={step.config}
                                  />
                                )}
                                {step.action === 'Scheduled' && (
                                  <Scheduled
                                    onChange={(config) => handleTriggerConfigChange(index, config)}
                                    initialValue={step.config.date}
                                  />
                                )}
                                {step.action === 'webhook' && (
                                  <WebhookTrigger
                                    onChange={(config) => handleTriggerConfigChange(index, config)}
                                    initialValue={step.config.date}
                                    currentUser={currentUser}
                                    initialConfig={step.config}
                                  />
                                )}
                              </div>
                            ) : (
                              <div className="action-selector">
                                <h3>Select Action</h3>
                                <select
                                  onChange={(e) => handleSelectAction(index, e.target.value)}
                                  value={step.action || ''}
                                >
                                  <option value="">Select Action Type</option>
                                  <option value="Condition">Condition</option>
                                  <option value="SendMessage">Send Message</option>
                                  <option value="SendHttpRequest">Send HTTP Request</option>
                                  <option value="IncomingMessage">Incoming Message</option>
                                  <option value="GambotAction">Gambot Action</option>
                                </select>
                                {step.action === 'Condition' && (
                                  <ConditionAction
                                    onChange={(config) => handleActionConfigChange(index, config)}
                                    previousQuestions={getButtonLabelsAndTemplateIds(steps)}
                                    steps={steps}
                                    currentUser={currentUser}
                                    conditionId={index}
                                    initialConfig={step.config}
                                  />
                                )}
                                {step.action === 'SendMessage' && (
                                  <OutboundMessageAction
                                    organization={currentUser?.organization}
                                    onChange={(config) => handleActionConfigChange(index, config)}
                                    initialConfig={step.config}
                                  />
                                )}
                                {step.action === 'SendHttpRequest' && (
                                  <SendHttpRequestAction
                                    steps={steps}
                                    onChange={(config) => handleHttpRequestResponse(index, config)}
                                    initialConfig={step.config}
                                  />
                                )}
                                {step.action === 'IncomingMessage' && (
                                  <IncomingMessageTrigger
                                    organization={currentUser?.organization}
                                    onChange={(config) => handleActionConfigChange(index, config)}
                                    initialConfig={step.config}
                                  />
                                )}
                                {step.action === 'GambotAction' &&(
                                  <GambotAction
                                  organization={currentUser?.organization}
                                    onChange={(config) => handleActionConfigChange(index, config)}
                                    initialConfig={step.config}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {steps.length > 0 && (
        <button onClick={() => handleAddAction(steps.length - 1)} className="add-action-button" type="button">+ Add Action</button>
      )}
    </div>
  );
};

export default WorkflowDesigner;
