import React, { useState } from 'react';
import './NewMessageTemplate.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import LookUp from '../General/Lookup';
import Select, { components } from 'react-select';

const NewMessageTemplate = (currentUser) => {
  console.log(currentUser);
  const [templateName, setTemplateName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [errors, setErrors] = useState({});

  const categories = [
    { name: 'Marketing', icon: '📢' },
    { name: 'Utility', icon: '🛠️' },
    { name: 'Auth', icon: '🔒' }
  ];


  // Handlers for input changes, form submission, etc.
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleTemplateNameChange = (event) => {
    const value = event.target.value
      .replace(/[^a-z0-9_]/gi, '') // Allow English letters, numbers, and underscores
      .toLowerCase(); // Convert to lowercase
    setTemplateName(value);
  };
  
  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
      setTemplateName((prevName) => prevName + '_');
    }
  };
  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
  };
  console.log(currentUser.currentUser.organization);
  const mode = "Create";
  const queryParams = new URLSearchParams({
    mode,
    templateName,
    selectedCategory,
    selectedLanguage: JSON.stringify(selectedLanguage),
    organization : currentUser.currentUser.organization
  }).toString();
  const handleSubmit = (event) => {
    console.log(event);
    event.preventDefault();
    const validationErrors = {};
  
    if (!templateName) {
      validationErrors.templateName = 'Template name is required.';
    }
    if (!selectedCategory) {
      validationErrors.selectedCategory = 'Category is required.';
    }
    if (!selectedLanguage) {
      validationErrors.selectedLanguage = 'Language is required.';
    }
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    // Clear errors if all validations pass
    setErrors({});
    console.log('Submitted:', { templateName, selectedCategory, selectedLanguage });
  };
  const isContinueDisabled = !templateName || !selectedCategory || !selectedLanguage;


  // Category selection, name input, language selection, and buttons
  return (
    <div className="new-message-template-container">
      <h1>New Message Template</h1>
      <form className='new-message-template-form' onSubmit={handleSubmit}>
      <div className="new-message-template-container-input-container">
      <input
      type="text"
      placeholder="Enter message template name..."
      value={templateName}
      onChange={handleTemplateNameChange}
      onKeyDown={handleKeyDown}
      className="template-name-input"
      required={true}
    />
    <small className="new-message-template-container-input-hint">
      Name must be in English, lowercase, and may include numbers and underscores. Press "Enter" to add an underscore.
    </small>
        </div>
        <div className="category-selection">
          {categories.map((category) => (
            <button
            
            key={category.name}
              className={`category-button ${selectedCategory === category.name ? 'active' : ''
                }`}
              onClick={() => setSelectedCategory(category.name)}
            >
              <span className="category-icon">{category.icon}</span>
              {category.name}
            </button>
            
          ))}
            {errors.selectedCategory && <small className="new-message-template-container-error-message">{errors.selectedCategory}</small>}

        </div>

        <div className="language-selection">
          {/* Use the LookUp component for language selection */}
          <LookUp
            options={languages.map((language) => ({ value: language, label: language }))}
            onChange={handleLanguageChange}
            placeholder="Select a language..."
            value={selectedLanguage} // Pass the selected value
            
          />
            {errors.selectedLanguage && <small className="new-message-template-container-error-message">{errors.selectedLanguage}</small>}

        </div>
        <br></br>
        <div className="new-message-template-action-buttons">
          <Link to="/Templates">
            <button className="new-message-template-action-buttons-cancel" type="button" onClick={() => { }}>
              Cancel
            </button>
          </Link>
          <Link
            to={{
              pathname: "/New-Template",
              search: `?${queryParams}`,
            }}
          >
            <button className="new-message-template-action-buttons-continue" type="submit" disabled={isContinueDisabled}>
              Continue
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default NewMessageTemplate;


  // Define your list of languages
  const languages = [
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'Azerbaijani',
    'Basque',
    'Belarusian',
    'Bengali',
    'Bosnian',
    'Bulgarian',
    'Catalan',
    'Cebuano',
    'Chichewa',
    'Chinese (Simplified)',
    'Chinese (Traditional)',
    'Corsican',
    'Croatian',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Esperanto',
    'Estonian',
    'Filipino',
    'Finnish',
    'French',
    'Frisian',
    'Galician',
    'Georgian',
    'German',
    'Greek',
    'Gujarati',
    'Haitian Creole',
    'Hausa',
    'Hawaiian',
    'Hebrew',
    'Hindi',
    'Hmong',
    'Hungarian',
    'Icelandic',
    'Igbo',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Kannada',
    'Kazakh',
    'Khmer',
    'Kinyarwanda',
    'Korean',
    'Kurdish (Kurmanji)',
    'Kyrgyz',
    'Lao',
    'Latin',
    'Latvian',
    'Lithuanian',
    'Luxembourgish',
    'Macedonian',
    'Malagasy',
    'Malay',
    'Malayalam',
    'Maltese',
    'Maori',
    'Marathi',
    'Mongolian',
    'Myanmar (Burmese)',
    'Nepali',
    'Norwegian',
    'Odia (Oriya)',
    'Pashto',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Romanian',
    'Russian',
    'Samoan',
    'Scots Gaelic',
    'Serbian',
    'Sesotho',
    'Shona',
    'Sindhi',
    'Sinhala',
    'Slovak',
    'Slovenian',
    'Somali',
    'Spanish',
    'Sundanese',
    'Swahili',
    'Swedish',
    'Tajik',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Turkish',
    'Turkmen',
    'Ukrainian',
    'Urdu',
    'Uyghur',
    'Uzbek',
    'Vietnamese',
    'Welsh',
    'Xhosa',
    'Yiddish',
    'Yoruba',
    'Zulu',
    // Add more languages as needed
  ];

