import React, { useState, useEffect } from 'react';
import TemplatesHandler from '../../Templates/TemplatesHandler';
import './OutboundMessageAction.css';

const OutboundMessageAction = ({ organization, onChange, initialConfig = {} }) => {
    const [messageType, setMessageType] = useState(initialConfig.messageType || 'regular');
    const [messageContent, setMessageContent] = useState(initialConfig.messageContent || '');
    const [templateConfig, setTemplateConfig] = useState(initialConfig.templateConfig || {});
    console.log(initialConfig);
    useEffect(() => {
        if (messageType === 'regular' && messageContent !== initialConfig.messageContent) {
            onChange({ messageType, messageContent });
        } else if (messageType === 'template' && JSON.stringify(templateConfig) !== JSON.stringify(initialConfig.templateConfig)) {
            onChange({ messageType, templateConfig });
        }
    }, [messageType, messageContent, templateConfig, initialConfig , onchange]);

    return (
        <div className="outbound-message-action">
            <label>
                Message Type:
                <select value={messageType} onChange={(e) => setMessageType(e.target.value)}>
                    <option value="regular">Regular</option>
                    <option value="template">Template</option>
                </select>
            </label>

            {messageType === 'regular' && (
                <div>
                    <label>
                        Message:
                        <textarea
                            className="message-textarea"
                            value={messageContent}
                            onChange={(e) => setMessageContent(e.target.value)}
                            placeholder="Enter your message here"
                        />
                    </label>
                </div>
            )}

            {messageType === 'template' && (
                <TemplatesHandler
                    organization={organization}
                    onTemplatesVariableChange={(template, variables, queries) => setTemplateConfig({ template, variables, queries })}
                    templateId={templateConfig.template?.Id}
                    templateVariableQuery={templateConfig.queries}
                    parent={'OutboundMessageAction'}
                />
            )}
        </div>
    );
};

export default OutboundMessageAction;
