import React from 'react';
import './AboutUs.css'; // CSS file
import GambotLogo from '../../../assets/GamBot_Logo.png'; // Adjust the path as necessary

const AboutUs = () => {
    return (
        <div className="about-page">
            <div className="logo-container">
                <img src={GambotLogo} alt="Gambot Logo" />
            </div>
            <div className="about-container">
                <h1>About Gambot</h1>
                <p>Gambot is at the forefront of revolutionizing chatbot automation services. Our innovative technology is designed to streamline communication and enhance user engagement. We're committed to providing top-notch solutions for businesses looking to automate their customer service and outreach.</p>
                <div className="contact-info">
        <h2>Contact Us</h2>
        <p>איש קשר : ניר סגס</p>
        <p>כתובת : ההדס 55 , 406000 תל מונד</p>
        <p>טלפון : +972505278310</p>
        <p> דואר אלקטרוני: info@gambot.co.il</p>
        {/* <p>Address: haadas 55, 4060000 Tel Mond</p> */}
        {/* <p>City : Tel Mond</p> */}
        {/* <p>State : Israel</p>  */}
        {/* <p>Phone: +972505278310</p> */}
        {/* <p>Email: Info@gambot.co.il</p>  */}
        {/* <p>Website: <a href="https://www.gambot.co.il" target="_blank" rel="noopener noreferrer">www.gambot.co.il</a></p> Add your business website URL here */}
    </div>
            </div>
        </div>
    );
}

export default AboutUs;
